import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import '../css/SaveDesigns.css';
import '../css/Global.css';
import Sidebar from '../jsx/Sidebar';


const SaveDesigns = () => {
  const [designs, setDesigns] = useState([]); 

  useEffect(() => {
    const fetchDesigns = async () => {
      try {
        const response = await axios.get('https://api.printforu.in/api/v1/adminSaveDesigns/getDesign');
        console.log(response.data); // Log to inspect data
        setDesigns(response.data);
      } catch (error) {
        console.error("Error fetching designs:", error);
      }
    };
    fetchDesigns();
  }, []);

  // Function to handle the download of the design
  const handleDownload = (filePath, title) => {
    // Create an anchor element and trigger the download
    const link = document.createElement('a');
    link.href = `https://api.printforu.in/public/${filePath}`;
    link.download = title; // This sets the file name for the downloaded file
    document.body.appendChild(link); // Append link to body
    link.click(); // Simulate click to start download
    document.body.removeChild(link); // Remove the link after download
  };

  return (
    <>
    <Sidebar />
    <Container className="dashb page-container fade-in mt-4">
      <h2 className="text-center mb-5 savet text-white">Saved Designs</h2>
      <Row>
        {designs.map((design) => (
          <Col lg={3} md={6} sm={12} key={design._id} className="mb-4">
            <Card className="design-card shadow-sm h-100">
              <Card.Img variant="top" src={`https://api.printforu.in/public/${design.filePath}`} alt={design.title} className="design-image"/>
              <Card.Body>
                <Card.Title>{design.title}</Card.Title>
              </Card.Body>
              <Card.Footer className="text-center">
                <Button 
                  variant="primary" 
                  className="btn-block" 
                  onClick={() => handleDownload(design.filePath, design.title)} // Call the download function
                >
                  Download
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
    </>
  );
};

export default SaveDesigns;