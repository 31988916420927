import React, { useState, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min"
import Logo from "../../img/background-photo/logo.png-removebg-preview.png";
import vect from "../../img/background-photo/no-background.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import '../css/port.css';

import Login from "./Login";
import Footer from "./Footer";
import Contact from "./Contact";
import Portfolio from "./Portfolio";
import Services from "./Services";
import ProductSection from "./ProductSection";
import About from "./About";
// import About from "./";

const Port = () => {
  const handlleClick = () => {

    // whats up icon
    window.open('https://wa.me/919503551157', '_blank'); // Replace with your WhatsApp number
  };

  //  font text 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 seconds

    return () => clearTimeout(timer);
  }, []);

  //  nav form 
  const [showLoginForm, setShowLoginForm] = useState(false); // State to toggle login form

  const handleLoginClick = () => {
    setShowLoginForm(true); // Show login form and hide everything else
  };

  const handleCloseLoginForm = () => {
    setShowLoginForm(false); 
  };

  return (
    <div>
      {loading ? (
        <div className="loader">
          <svg height="0" width="0" viewBox="0 0 64 64" className="absolute">
            <defs xmlns="http://www.w3.org/2000/svg">
              <linearGradient
                gradientUnits="userSpaceOnUse"
                y2="2"
                x2="0"
                y1="62"
                x1="0"
                id="b"
              >
                <stop stopColor="#973BED"></stop>
                <stop stopColor="#007CFF" offset="1"></stop>
              </linearGradient>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                y2="0"
                x2="0"
                y1="64"
                x1="0"
                id="c"
              >
                <stop stopColor="#FFC800"></stop>
                <stop stopColor="#F0F" offset="1"></stop>
                <animateTransform
                  repeatCount="indefinite"
                  keySplines=".42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1"
                  keyTimes="0; 0.125; 0.25; 0.375; 0.5; 0.625; 0.75; 0.875; 1"
                  dur="8s"
                  values="0 32 32;-270 32 32;-270 32 32;-540 32 32;-540 32 32;-810 32 32;-810 32 32;-1080 32 32;-1080 32 32"
                  type="rotate"
                  attributeName="gradientTransform"
                ></animateTransform>
              </linearGradient>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                y2="2"
                x2="0"
                y1="62"
                x1="0"
                id="d"
              >
                <stop stopColor="#00E0ED"></stop>
                <stop stopColor="#00DA72" offset="1"></stop>
              </linearGradient>
            </defs>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 64 64"
            height="64"
            width="64"
            className="inline-block"
          >
            <path
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="8"
              stroke="url(#b)"
              d="M 54.722656,3.9726563 A 2.0002,2.0002 0 0 0 54.941406,4 h 5.007813 C 58.955121,17.046124 49.099667,27.677057 36.121094,29.580078 a 2.0002,2.0002 0 0 0 -1.708985,1.978516 V 60 H 29.587891 V 31.558594 A 2.0002,2.0002 0 0 0 27.878906,29.580078 C 14.900333,27.677057 5.0448787,17.046124 4.0507812,4 H 9.28125 c 1.231666,11.63657 10.984383,20.554048 22.6875,20.734375 a 2.0002,2.0002 0 0 0 0.02344,0 c 11.806958,0.04283 21.70649,-9.003371 22.730469,-20.7617187 z"
              className="dash"
              id="y"
              pathLength="360"
            ></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style={{ "--rotation-duration": "0ms", "--rotation-direction": "normal" }}
            viewBox="0 0 64 64"
            height="64"
            width="64"
            className="inline-block"
          >
            <path
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="10"
              stroke="url(#c)"
              d="M 32 32 m 0 -27 a 27 27 0 1 1 0 54 a 27 27 0 1 1 0 -54"
              className="spin"
              id="o"
              pathLength="360"
            ></path>
          </svg>
          <div className="w-2"></div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style={{ "--rotation-duration": "0ms", "--rotation-direction": "normal" }}
            viewBox="0 0 64 64"
            height="64"
            width="64"
            className="inline-block"
          >
            <path
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="8"
              stroke="url(#d)"
              d="M 4,4 h 4.6230469 v 25.919922 c -0.00276,11.916203 9.8364941,21.550422 21.7500001,21.296875 11.616666,-0.240651 21.014356,-9.63894 21.253906,-21.25586 a 2.0002,2.0002 0 0 0 0,-0.04102 V 4 H 56.25 v 25.919922 c 0,14.33873 -11.581192,25.919922 -25.919922,25.919922 a 2.0002,2.0002 0 0 0 -0.0293,0 C 15.812309,56.052941 3.998433,44.409961 4,29.919922 Z"
              className="dash"
              id="u"
              pathLength="360"
            ></path>
          </svg>
          <div className="text-container">
            <h1 className="animated-text">PRINT FOR U</h1>
          </div>
        </div>
      ) : (
        <div>

          {/* Main website content goes here */}

          <button
            onClick={handlleClick}
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              zIndex: 1000,
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            <FontAwesomeIcon icon={faWhatsapp} size="3x" color="#25D366" />
            <span style={{ fontSize: '16px', color: '#25D366' }}>Contact Us</span>
          </button>



          {/*  navigation */}
          {showLoginForm ? (
            // Show only the login form when "Login" is clicked
            <div className="login-form-container">
              <Login /> {/* Your login form component */}
              <button
                className="btn btn-secondary mt-3"
                onClick={handleCloseLoginForm}
              >
                Back to Menu
              </button>
            </div>
          ) : (
            // Show the navigation bar and everything else if login form is not clicked
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
              <div className="container">
                <a className="navbar-brand" href="#">
                  <img
                    src={Logo}
                    alt="Company Logo"
                    className="d-inline-block align-text-top"
                    style={{ borderRadius: "4px" }}
                  />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <a className="nav-link" href="#home">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#about">
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#services">
                        Services
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#portfolio">
                        Portfolio
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#contact">
                        Contact
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#productSection">
                        Our Products
                      </a>
                    </li>
                    <li className="nav-item">
                      {/* Login button */}
                      <button className="btn btn-primary" onClick={handleLoginClick}>
                        Login
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          )}

          {/* animated text & vector */}
          <section className="home1" style={{background: "linear-gradient(90deg, rgba(2,180,228,1) 18%, rgba(8,138,175,1) 47%, rgba(28,0,3,1) 100%)"}}id="home" >
            {/* Scrolling Text Box */}
            <div className="scrolling-text-box">
            <p className="text-info"><span>Premium printing Anytime Anywhere...!</span> <span style={{ marginLeft: '100px' }}>Get the best quality prints at affordable prices!</span></p>
            </div>

            <div className="print-vect row">
              <div className="print-row col-md-6">
                <div className="col-12 text-center">
                <h3 className="animate-charcter" style={{ fontSize: '60px' }}>Print For You</h3>
                <p className="animate-charcter">Your Printing Partner</p>
                </div>
              </div>
              <section className="col-md-6 IMGMAIN">
                <div className="col-12" style={{ marginRight: '20px' }}>
                  <img
                    src={vect}
                    alt="SEO"
                    className="vect"
                  />
                </div>
              </section>
            </div>
          </section>

          {/* About us */}
          <About />

          {/* service */}
          <Services />

          {/* portfolio */}
          <Portfolio />

          {/* ProductSection */}
          <ProductSection/>

          {/* contact  */}
          <Contact />

          {/* Footer */}
          <Footer />

        </div>
      )}
    </div>
  );
};

export default Port;
