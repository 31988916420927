// import React from 'react';
// import '../css/Notification.css';
// import '../css/Global.css';

// const notificationsData = [
//   {
//     title: 'New Order Received',
//     time: '2 mins ago',
//     description: 'You have received a new order for a custom design poster.',
//     type: 'order',
//   },
//   {
//     title: 'Design Approved',
//     time: '10 mins ago',
//     description: 'Your recent design for "Wedding Invitation" has been approved.',
//     type: 'approval',
//   },
//   {
//     title: 'Payment Successful',
//     time: '1 hour ago',
//     description: 'Payment for your recent order #1234 has been processed successfully.',
//     type: 'payment',
//   },
//   {
//     title: 'Order Shipped',
//     time: '3 hours ago',
//     description: 'Your order #1234 has been shipped and is on its way.',
//     type: 'shipping',
//   },
// ];

// const Notifications = () => {
//   return (
//     <div className="notifications-container">
//       <h2 className="notifications-title">Notifications</h2>
//       <ul className="notifications-list">
//         {notificationsData.map((notification, index) => (
//           <li className={`notification-card ${notification.type}`} key={index}>
//             <div className="notification-header">
//               <h3>{notification.title}</h3>
//               <span className="notification-time">{notification.time}</span>
//             </div>
//             <p>{notification.description}</p>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default Notifications;



import React, { useEffect, useState } from 'react';
import '../css/Notification.css';
import '../css/Global.css';
import Sidebar from './Sidebar';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const response = await fetch('https://api.printforu.in/api/v1/adminNotification/getNotifications');
      const data = await response.json();
      setNotifications(data);
    };

    fetchNotifications();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="dashb notifications-container">
        <h2 className="notifications-title">Notifications</h2>
        <ul className="notifications-list">
          {notifications.map((notification, index) => (
            <li className={`notification-card ${notification.type}`} key={index}>
              <div className="notification-header">
                <h3>{notification.title}</h3>
                <span className="notification-time">{notification.time}</span>
              </div>
              <p>{notification.description}</p>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Notifications;
