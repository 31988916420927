import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Offcanvas, Button, Modal, Form } from 'react-bootstrap';
import pro from '../../../img/background-photo/BizFavicon.png'; 

import '../css/Sidebar.css';
import '../css/Global.css';

const Sidebar = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [profileName, setProfileName] = useState('');

  // Fetch profile data from the backend
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch('https://api.printforu.in/api/v1/adminProfile/profile');
        if (!response.ok) {
          throw new Error('Failed to fetch profile');
        }
        const data = await response.json();
        setProfileName(data.name); // Correctly set the profile name
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark-mode', !isDarkMode);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const handleProfileNameChange = (e) => {
    setProfileName(e.target.value);
  };

  const handleSaveProfile = () => {
    setShowEditModal(false);
    // Optionally, add a function to save the updated profile name to the backend here
  };

  // Logging the logo path to ensure it's loaded correctly
  console.log(pro);

  return (
    <>
      {/* Hamburger button for mobile view */}
      <Button className="sidebar-toggle d-md-none" onClick={toggleSidebar} variant="primary">
        ☰
      </Button>

      {/* Offcanvas for sidebar in small screens */}
      <Offcanvas show={isOpen} onHide={toggleSidebar} placement="start" className={isDarkMode ? 'dark' : ''}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="profile-section text-center">
            <div className="profile-card">
              {/* Profile Image */}
              <img 
                src={pro ? pro : "https://via.placeholder.com/150"} 
                alt="Bizkranti Logo" 
                className="profile-img" 
              />
              <h3 className="profile-name">{profileName}</h3>
            </div>
          </div>
          <nav className="menu-section mt-4">
            <ul className="list-unstyled">
              <li>
                <Link to="/profileDashboard" onClick={toggleSidebar}>
                  <i className="fas fa-user" style={{ color: '#FF6F61' }}></i> Profile Dashboard
                </Link>
              </li>
              <li>
                <Link to="/ourOrder" onClick={toggleSidebar}>
                  <i className="fas fa-receipt" style={{ color: '#6A5ACD' }}></i> Our Order
                </Link>
              </li>
              <li>
                <Link to="/saveDesigns" onClick={toggleSidebar}>
                  <i className="fas fa-save" style={{ color: '#28A745' }}></i> Save Designs/Projects
                </Link>
              </li>
              <li>
                <Link to="/notifaction" onClick={toggleSidebar}>
                  <i className="fas fa-bell" style={{ color: '#FFC107' }}></i> Notifications
                </Link>
              </li>
              <li>
                <Link to="/orderUpdates" onClick={toggleSidebar}>
                  <i className="fas fa-history" style={{ color: '#17A2B8' }}></i> Order Updates
                </Link>
              </li>
              <li>
                <Link to="/uploadDesign" onClick={toggleSidebar}>
                  <i className="fas fa-upload" style={{ color: '#FF6347' }}></i> Upload Designs
                </Link>
              </li>
              <li>
                <Link to="/paymentPage" onClick={toggleSidebar}>
                  <i className="fas fa-credit-card" style={{ color: '#28A745' }}></i> Payment
                </Link>
              </li>
              <li>
                <Link to="/faq" onClick={toggleSidebar}>
                  <i className="fas fa-question-circle" style={{ color: '#FFC107' }}></i> FAQ
                </Link>
              </li>
              <li>
                <Link to="/support" onClick={toggleSidebar}>
                  <i className="fas fa-headset"></i> Support
                </Link>
              </li>
            </ul>
          </nav>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Sidebar for larger screens */}
      <div className={`sidebar d-none d-md-block ${isDarkMode ? 'dark' : ''}`}>
        <div className="profile-section text-center">
          <div className="profile-card">
            <img 
              src={pro ? pro : "https://via.placeholder.com/150"} 
              alt="Bizkranti Logo" 
              className="profile-img" 
            />
            <h3 className="profile-name">{profileName}</h3>
          </div>
        </div>
        <nav className="menu-section mt-4">
          <ul className="list-unstyled">
            <li>
              <Link to="/profileDashboard">
                <i className="fas fa-user" style={{ color: '#FF6F61' }}></i> Profile Dashboard
              </Link>
            </li>
            <li>
              <Link to="/ourOrder">
                <i className="fas fa-receipt" style={{ color: '#6A5ACD' }}></i> Our Order
              </Link>
            </li>
            <li>
              <Link to="/saveDesigns">
                <i className="fas fa-save" style={{ color: '#28A745' }}></i> Save Designs
              </Link>
            </li>
            <li>
              <Link to="/notifaction">
                <i className="fas fa-bell" style={{ color: '#FFC107' }}></i> Notifications
              </Link>
            </li>
            <li>
              <Link to="/orderUpdates">
                <i className="fas fa-history" style={{ color: '#17A2B8' }}></i> Order Updates
              </Link>
            </li>
            <li>
              <Link to="/uploadDesign">
                <i className="fas fa-upload" style={{ color: '#FF6347' }}></i> Upload Designs
              </Link>
            </li>
            <li>
              <Link to="/paymentPage">
                <i className="fas fa-credit-card" style={{ color: '#28A745' }}></i> Payment
              </Link>
            </li>
            <li>
              <Link to="/faq">
                <i className="fas fa-question-circle" style={{ color: '#FFC107' }}></i> FAQ
              </Link>
            </li>
            <li>
              <Link to="/support">
                <i className="fas fa-headset"></i> Support
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      {/* Modal for Editing Profile */}
      <Modal show={showEditModal} onHide={handleEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formProfileName">
              <Form.Label>Profile Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                value={profileName}
                onChange={handleProfileNameChange}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleSaveProfile} className="mt-3 w-100">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Sidebar;
