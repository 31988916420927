// App.js
import React from 'react';
import './App.css';
import AppRoute from './components/Route/AppRoute';

function App() {
    return (
      <div>
        <AppRoute />
      </div>
    );
}

export default App;




// Got it! You want each new user to have a "fresh" 
// view upon their first login, showing no data that 
// belongs to other users. Also, you want existing 
// users to only see their own data upon login.