import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../css/port.css';

const Portfolio = () => {
      // port code....
  const [selectedGallery, setSelectedGallery] = useState("gallery1");
  const galleries = {
    gallery1: [
      require("../../img/portfolio/first.jpg"),
      require("../../img/portfolio/second.jpg"),
      require("../../img/portfolio/third.jpg"),
      require("../../img/portfolio/four.jpg"),
    ],
    gallery2: [
      require("../../img/portfolio/visiting1.jpg"),
      require("../../img/portfolio/visiting2.jpg"),
      require("../../img/portfolio/visiting3.jpg"),
      require("../../img/portfolio/visiting4.jpg"),
    ],
    gallery3: [
      require("../../img/portfolio/icard1.jpg"),
      require("../../img/portfolio/icard2.jpg"),
      require("../../img/portfolio/icard3.jpg"),
      require("../../img/portfolio/icard4.jpg"),
    ],
    gallery4: [
      require("../../img/portfolio/led-frem1.jpg"),
      require("../../img/portfolio/led-frem2.jpg"),
      require("../../img/portfolio/led-frem3.jpg"),
      require("../../img/portfolio/led-frem4.jpg"),
    ],
    gallery5: [
      require("../../img/portfolio/flex1.jpg"),
      require("../../img/portfolio/flex2.jpg"),
      require("../../img/portfolio/flex3.jpg"),
      require("../../img/portfolio/flex4.jpg"),
    ],
    gallery6: [
      require("../../img/portfolio/mom1.jpg"),
      require("../../img/portfolio/mom2.jpg"),
      require("../../img/portfolio/mom3.jpg"),
      require("../../img/portfolio/mom4.jpg"),
    ],
    gallery7: [
      require("../../img/portfolio/banner1.jpg"),
      require("../../img/portfolio/banner2.jpg"),
      require("../../img/portfolio/banner3.jpg"),
      require("../../img/portfolio/banner4.jpg"),
    ],
  };
  
    const handleButtonClick = (galleryKey) => {
        setSelectedGallery(galleryKey);
      };

    return (
        <>
            {/* Portfolio */}


            <section className="portfolio" id="portfolio">
            <h1 className="text-center mb-5 mt-5 text-white">
              OUR <span style={{ color: "#ecfd2e" }}> PORTFOLIO</span>
            </h1>

            {/* Portfolio Buttons */}

            <div className="d-flex flex-wrap justify-content-center mb-5 portfolio">
              <button
                className="btn btn-primary mx-2 my-2 visiting-cards"
                onClick={() => handleButtonClick("gallery1")}
              >
                Visiting Cards
              </button>
              <button
                className="btn btn-primary mx-2 my-2 invitation-cards"
                onClick={() => handleButtonClick("gallery2")}
              >
                Invitation Cards
              </button>
              <button
                className="btn btn-primary mx-2 my-2 id-cards"
                onClick={() => handleButtonClick("gallery3")}
              >
                Id Cards
              </button>
              <button
                className="btn btn-primary mx-2 my-2 led-frames"
                onClick={() => handleButtonClick("gallery4")}
              >
                Led Frames
              </button>
              <button
                className="btn btn-primary mx-2 my-2 flex"
                onClick={() => handleButtonClick("gallery5")}
              >
                Flex
              </button>
              <button
                className="btn btn-primary mx-2 my-2 momentos"
                onClick={() => handleButtonClick("gallery6")}
              >
                Momentos
              </button>
              <button
                className="btn btn-primary mx-2 my-2 roll-up-banner"
                onClick={() => handleButtonClick("gallery7")}
              >
                Roll up Banner
              </button>
            </div>


            {/* Portfolio Images */}

            <div className="container">
              <div className="row">
                {galleries[selectedGallery]?.map((image, index) => (
                  <div key={index} className="col-6 col-md-4 col-lg-3 mb-4">
                    <img
                      src={image}
                      alt={`Gallery ${selectedGallery} Image ${index + 1}`}
                      className="img-fluid zoom-effect"
                    />
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
    );
};

export default Portfolio;