import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../css/port.css';

const About = () => {
    return (
        <>
            {/* About */}
           
          <div className="" id="about">
            <div className="animated-section d-flex align-items-center justify-content-center text-center">
              <div className="content p-4">
                <h1 className="welcome">
                  Welcome to <span style={{ color: "#ecfd2e" }}>Printforyou</span>
                </h1>
                <p className="mt-4">
                  Your one-stop solution for all your printing needs. We offer a
                  comprehensive range of printing services, both online and offline,
                  designed to bring your creative ideas to life. At Printforyou, we
                  understand the importance of quality and timing. That’s why we are
                  committed to delivering creative and attractive designs on time. Our
                  team of experienced designers and printing experts work closely with you
                  to ensure that your vision is perfectly captured in every print. We take
                  pride in our ability to cater to both individuals and businesses,
                  offering customized solutions that meet your specific needs. Whether
                  you’re looking for a one-off print or bulk orders, our state-of-the-art
                  technology and efficient processes ensure that you receive the highest
                  quality prints with a quick turnaround. Join us at Printforyou, where
                  your print is our priority. Let us help you make a lasting impression
                  with designs that stand out and prints that speak volumes.
                </p>
              </div>
            </div>
          </div>
        </>
    );
};

export default About;
