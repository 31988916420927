import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Logo from "../../img/background-photo/logo.png-removebg-preview.png";
import '../css/port.css';

const Footer = () => {
    return (
        <>
            {/* footer */}
            <footer className="pt-4 bg-white text-dark">
                <div className="container pt-4">
                    <div className="row">
                        {/* Company Information */}
                        <div className="col-12 col-md-3 mb-4">
                            <h5>
                                <a href="#">
                                    <img
                                        className="custom-logo"
                                        src={Logo}
                                        alt="Company Logo"
                                        style={{ width: "100px" }}
                                    />
                                </a>
                            </h5>
                            <p className="text-justify">
                                We take pride in our ability to cater to both individuals and businesses, offering customized solutions that meet your specific needs.
                            </p>
                        </div>
                        {/* Quick Links */}
                        <div className="col-12 col-md-3 mb-4" style={{ textAlign: 'center' }}>
                            <h5 className="text-danger">Quick Links</h5>
                            <ul className="list-unstyled pl-0">
                                <li><a href="#home" className="text-dark">Home</a></li>
                                <li><a href="#about" className="text-dark">About Us</a></li>
                                <li><a href="#services" className="text-dark">Services</a></li>
                                <li><a href="#portfolio" className="text-dark">Portfolio</a></li>
                                <li><a href="#contact" className="text-dark">Contact</a></li>
                            </ul>
                        </div>
                        {/* Additional Links */}
                        <div className="col-12 col-md-3 mb-4" style={{ textAlign: 'center' }}>
                            <h5 className="text-danger">Additional Links</h5>
                            <ul className="list-unstyled">
                                <li><a href="./desclaimer.html" className="text-dark">Disclaimer</a></li>
                                <li><a href="./privacy.html" className="text-dark">Privacy Policy</a></li>
                                <li><a href="./refund.html" className="text-dark">Refund Policy</a></li>
                                <li><a href="./terms.html" className="text-dark">Terms and Conditions</a></li>
                            </ul>
                        </div>
                        {/* Contact Information */}
                        <div className="col-12 col-md-3 mb-4" style={{ textAlign: 'center' }}>
                            <h5 className="text-danger">Contact</h5>
                            <ul className="list-unstyled">
                                <li><i className="fas fa-map-marker-alt mr-2"></i>123 Street, City, Country</li>
                                <li><i className="fas fa-phone mr-2"></i>+123 456 7890</li>
                                <li><i className="fas fa-envelope mr-2"></i>Printforyou@company.com</li>
                            </ul>
                            {/* Social Media Icons */}
                            <div className="mt-3">
                                <a href="#" className="text-dark mr-3"><i className="fab fa-facebook-f"></i></a>
                                <a href="#" className="text-dark mr-3"><i className="fab fa-twitter"></i></a>
                                <a href="#" className="text-dark mr-3"><i className="fab fa-linkedin-in"></i></a>
                                <a href="#" className="text-dark"><i className="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Back to top button */}
                <a href="#top" className="back-to-top-link">
                    <button className="button">
                        <svg className="svgIcon" viewBox="0 0 384 512">
                            <path
                                d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                            ></path>
                        </svg>
                    </button>
                </a>

                {/* Footer copyright */}
                <div className="text-center py-3 mt-3 bg-gray text-dark">
                    <p className="mb-0">&copy; 2024 Print for you. All rights reserved.</p>
                </div>
            </footer>
        </>
    );
};

export default Footer;
