// // SuperAdmin/SaveDesigns.jsx
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Container, Row, Col, Card, Button, Form, Alert } from 'react-bootstrap';

// const SaveDesigns = () => {
//   const [title, setTitle] = useState('');
//   const [image, setImage] = useState(null);
//   const [message, setMessage] = useState('');
//   const [error, setError] = useState('');
//   const [designs, setDesigns] = useState([]); // State for designs

//   // Load designs on component mount
//   useEffect(() => {
//     const fetchDesigns = async () => {
//       try {
//         const response = await axios.get('http://localhost:5000/api/v1/adminSaveDesigns/getDesign');
//         setDesigns(response.data);
//       } catch (err) {
//         console.error('Error fetching designs:', err);
//         setError('Failed to load designs.');
//       }
//     };
//     fetchDesigns();
//   }, []);

//   const handleImageChange = (e) => {
//     setImage(e.target.files[0]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!title || !image) {
//       setError('Please provide both title and image.');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('title', title);
//     formData.append('file', image); // 'file' to match backend

//     try {
//       const response = await axios.post('http://localhost:5000/api/v1/adminSaveDesigns/uploadDesign', formData, {
//         headers: { 'Content-Type': 'multipart/form-data' },
//       });

//       setMessage('Design uploaded successfully!');
//       setError('');
//       setTitle('');
//       setImage(null);
//       setDesigns((prevDesigns) => [...prevDesigns, response.data]); // Update designs
//     } catch (err) {
//       console.error('Error uploading design:', err);
//       setError('Failed to upload design. Please try again.');
//       setMessage('');
//     }
//   };

//   const handleDownload = (filePath, title) => {
//     const link = document.createElement('a');
//     link.href = `http://localhost:5000/public/${filePath}`;
//     link.download = title;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   return (
//     <>
//       <Container className="mt-4">
//         <h2 className="text-center mb-4">Upload Design</h2>
//         {message && <Alert variant="success">{message}</Alert>}
//         {error && <Alert variant="danger">{error}</Alert>}
//         <Form onSubmit={handleSubmit}>
//           <Form.Group controlId="formTitle">
//             <Form.Label>Title</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter design title"
//               value={title}
//               onChange={(e) => setTitle(e.target.value)}
//               required
//             />
//           </Form.Group>

//           <Form.Group controlId="formImage">
//             <Form.Label>Image</Form.Label>
//             <Form.Control
//               type="file"
//               accept="image/*"
//               onChange={handleImageChange}
//               required
//             />
//           </Form.Group>

//           <Button variant="primary" type="submit" className="mt-3">
//             Upload Design
//           </Button>
//         </Form>
//       </Container>

//       <Container className="dashb page-container fade-in mt-4">
//         <h2 className="text-center mb-5 savet text-white">Saved Designs</h2>
//         <Row>
//           {designs.map((design) => (
//             <Col lg={3} md={6} sm={12} key={design._id} className="mb-4">
//               <Card className="design-card shadow-sm h-100">
//                 <Card.Img 
//                   variant="top" 
//                   src={`http://localhost:5000/public/${design.filePath}`} 
//                   alt={design.title} 
//                   className="design-image" 
//                 />
//                 <Card.Body>
//                   <Card.Title>{design.title}</Card.Title>
//                 </Card.Body>
//                 <Card.Footer className="text-center">
//                   <Button
//                     variant="primary"
//                     className="btn-block"
//                     onClick={() => handleDownload(design.filePath, design.title)}
//                   >
//                     Download
//                   </Button>
//                 </Card.Footer>
//               </Card>
//             </Col>
//           ))}
//         </Row>
//       </Container>
//     </>
//   );
// };

// export default SaveDesigns;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button, Form, Alert, Modal } from 'react-bootstrap';
import Sidebar from './Sidebar';

const SaveDesigns = () => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [designs, setDesigns] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editDesignId, setEditDesignId] = useState(null);

  // Load designs on component mount
  useEffect(() => {
    fetchDesigns();
  }, []);

  const fetchDesigns = async () => {
    try {
      const response = await axios.get('https://api.printforu.in/api/v1/adminSaveDesigns/getDesign');
      setDesigns(response.data);
    } catch (err) {
      console.error('Error fetching designs:', err);
      setError('Failed to load designs.');
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !image) {
      setError('Please provide both title and image.');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('file', image);

    try {
      const response = await axios.post('hhttps://api.printforu.in/api/v1/adminSaveDesigns/uploadDesign', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setMessage('Design uploaded successfully!');
      setError('');
      setTitle('');
      setImage(null);
      setDesigns((prevDesigns) => [...prevDesigns, response.data]);
    } catch (err) {
      console.error('Error uploading design:', err);
      setError('Failed to upload design. Please try again.');
      setMessage('');
    }
  };

  const handleDownload = (filePath, title) => {
    const link = document.createElement('a');
    link.href = `https://api.printforu.in/public/${filePath}`;
    link.download = title;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.printforu.in/api/v1/adminSaveDesigns/deleteDesign/${id}`);
      setDesigns((prevDesigns) => prevDesigns.filter((design) => design._id !== id));
      setMessage('Design deleted successfully!');
    } catch (err) {
      console.error('Error deleting design:', err);
      setError('Failed to delete design.');
    }
  };

  const handleEdit = (design) => {
    setEditDesignId(design._id);
    setTitle(design.title);
    setImage(null); // Reset image to not overwrite it unless updated
    setShowEditModal(true);
  };

  const handleUpdate = async () => {
    if (!title) {
      setError('Title is required.');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    if (image) formData.append('file', image);

    try {
      await axios.put(`https://api.printforu.in/api/v1/adminSaveDesigns/updateDesign/${editDesignId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setMessage('Design updated successfully!');
      setShowEditModal(false);
      fetchDesigns(); // Reload designs after update
      setTitle('');
      setImage(null);
    } catch (err) {
      console.error('Error updating design:', err);
      setError('Failed to update design.');
    }
  };

  return (
    <>
    <Sidebar/>
      <Container className="mt-4">
        <h2 className="text-center mb-4">Upload Design</h2>
        {message && <Alert variant="success">{message}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter design title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formImage">
            <Form.Label>Image</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-3">
            Upload Design
          </Button>
        </Form>
      </Container>

      <Container className="dashb page-container fade-in mt-4">
        <h2 className="text-center mb-5 savet text-white">Saved Designs</h2>
        <Row>
          {designs.map((design) => (
            <Col lg={3} md={6} sm={12} key={design._id} className="mb-4">
              <Card className="design-card shadow-sm h-100">
                <Card.Img 
                  variant="top" 
                  src={`https://api.printforu.in/public/${design.filePath}`} 
                  alt={design.title} 
                  className="design-image" 
                />
                <Card.Body>
                  <Card.Title>{design.title}</Card.Title>
                </Card.Body>
                <Card.Footer className="text-center">
                  <Button
                    variant="primary"
                    onClick={() => handleDownload(design.filePath, design.title)}
                  >
                    Download
                  </Button>
                  <Button
                    variant="warning"
                    className="mx-2"
                    onClick={() => handleEdit(design)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(design._id)}
                  >
                    Delete
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Design</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter new design title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="editImage">
              <Form.Label>Image</Form.Label>
              <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SaveDesigns;
