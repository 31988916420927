// //Admin/Support.jsx
// user
import React, { useState, useEffect } from 'react'; // Added useEffect import
import axios from 'axios'; // Added axios import
import { Container, Row, Col, Form, Button, Card, Alert } from 'react-bootstrap'; // Added Alert for messages
import '../css/Support.css';
import '../css/Global.css';
import Sidebar from './Sidebar';

const UserSupport = () => {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    message: '',
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [isError, setIsError] = useState(false); // State to track error
  const [chatVisible, setChatVisible] = useState(false); // State to manage chat visibility
  const [messages, setMessages] = useState([]); // State for chat messages
  const [newMessage, setNewMessage] = useState(''); // State for new message input

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.printforu.in/api/v1/adminSupport/support', formData);
      setResponseMessage(response.data.message);
      setIsError(false); // Clear error state on success
      setFormData({ fullname: '', email: '', message: '' }); // Reset form
    } catch (error) {
      setResponseMessage('Failed to submit support request. Please check your connection.'); // Updated error message
      setIsError(true); // Set error state
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const message = {
        text: newMessage,
        sender: 'You',
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        isAdmin: false, // Indicates the message is from the user
      };

      setMessages((prevMessages) => [...prevMessages, message]);
      setNewMessage('');

      try {
        // Send message to backend
        await axios.post('https://api.printforu.in/api/v1/liveChat/send', {
          sender: message.sender,
          text: message.text,
          isAdmin: false,
        });
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    }
  };

  useEffect(() => {
    // Fetch messages from backend on component mount
    const fetchMessages = async () => {
      try {
        const response = await axios.get('https://api.printforu.in/api/v1/liveChat/messages');
        setMessages(response.data.map(msg => ({
          ...msg,
          timestamp: new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        })));
      } catch (error) {
        console.error('Failed to fetch messages:', error);
      }
    };

    fetchMessages();
  }, []);

  return (
    <>
      <Sidebar />
      <Container fluid className="dashb py-4">
        <h2 className="text-center mb-4 text-white">Customer Support</h2>

        {/* Display Response Message */}
        {responseMessage && (
          <Row className="mb-3">
            <Col>
              <Alert variant={isError ? 'danger' : 'success'} className="text-center">
                {responseMessage}
              </Alert>
            </Col>
          </Row>
        )}

        {/* Support Options */}
        <Row className="mb-5 text-center">
          <Col xs={12} md={4} className="mb-4">
            <Card className="shadow-sm p-3 bg-white rounded">
              <Card.Body>
                <i className="fas fa-envelope fa-2x mb-3 text-primary"></i>
                <Card.Title>Email Support</Card.Title>
                <Card.Text>
                  <a href="mailto:support@yourwebsite.com">support@yourwebsite.com</a>.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <Card
              className="shadow-sm p-3 bg-white rounded"
              onClick={() => setChatVisible(true)} // Open chatbox on click
              style={{ cursor: 'pointer' }} // Change cursor to pointer
            >
              <Card.Body>
                <i className="fas fa-comments fa-2x mb-3 text-success"></i>
                <Card.Title>Live Chat</Card.Title>
                <Card.Text>
                  Chat with our support team 24/7.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="shadow-sm p-3 bg-white rounded">
              <Card.Body>
                <i className="fas fa-phone fa-2x mb-3 text-danger"></i>
                <Card.Title>Phone Support</Card.Title>
                <Card.Text>
                  Call us at <a href="tel:+1234567890">+123-456-7890</a>.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Contact Form */}
        <Row className="justify-content-center">
          <Col className='mas' lg={5}>
            <h4 className="text-center mb-4 text-white">Send Us a Message</h4>
            <Card className="shadow-sm p-2 bg-white rounded">
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="name" className="mb-2">
                  <Form.Label><b>Full Name</b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    name="fullname"
                    value={formData.fullname}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="email" className="mb-2">
                  <Form.Label><b>Email Address</b></Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="message" className="mb-2">
                  <Form.Label><b>Message</b></Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Write your message here..."
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Submit
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Chatbox */}
      {chatVisible && (
        <div className="chatbox-overlay">
          <div className="chatbox-container">
            <div className="chatbox-header card-header bg-success text-white text-center">
              Chat Support
              <button
                className="btn-close float-end"
                onClick={() => setChatVisible(false)} // Close chatbox on click
                aria-label="Close"
              ></button>
            </div>
            <div className="chatbox-messages card-body overflow-auto">
              {messages.map((message, index) => (
                <div key={index} className={`message ${message.sender === 'You' ? 'sent' : 'received'} mb-2`}>
                  <div className="message-text">{message.text}</div>
                  <div className="message-time text-muted small">{message.timestamp}</div>
                </div>
              ))}
            </div>
            <div className="chatbox-input d-flex p-2">
              <input
                type="text"
                className="form-control me-2"
                placeholder="Type a message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
              />
              <button className="btn btn-primary" onClick={handleSendMessage}>
                Send
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserSupport;
