// src/components/Contact.js
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import axios from 'axios'; // Import Axios
import '../css/port.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    pincode: "",
    phone: "",
    message: "",
  });

  // Handle input change
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);

    try {
      // Send data to backend
      const response = await axios.post('https://api.printforu.in/api/v1/contact/submit', formData);
      alert(response.data.message); // Show success message
    } catch (error) {
      console.error("Error submitting the form", error);
      alert("Failed to submit the form. Please try again later.");
    }
  };

  return (
    <>
      <section id="contact" className="pt-5">
        <div className="CONTACT-container">
          <h1 className="text-center mt-5 text-blue">
            GET IN <span style={{ color: "#ecfd2e" }}>TOUCH</span>
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="row">
              {/* Name Field */}
              <div className="col-md-6 mb-3">
                <label htmlFor="name" className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* Email Field */}
              <div className="col-md-6 mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              {/* Address Field */}
              <div className="col-md-12 mb-3">
                <label htmlFor="address" className="form-label">Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="Your Address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              {/* Pincode Field */}
              <div className="col-md-6 mb-3">
                <label htmlFor="pincode" className="form-label">Pincode</label>
                <input
                  type="text"
                  className="form-control"
                  id="pincode"
                  placeholder="Your Pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* Phone Number Field */}
              <div className="col-md-6 mb-3">
                <label htmlFor="phone" className="form-label">Phone Number</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="Your Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              {/* Message Field */}
              <div className="col-md-12 mb-3">
                <label htmlFor="message" className="form-label">Message</label>
                <textarea
                  className="form-control"
                  id="message"
                  rows="5"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
            </div>
            {/* Submit Button */}
            <div className="text-center">
              <button type="submit" className="btn btn-skyblue">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
