// ProfileDashboard.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Container, Nav, Button, Row, Col, Card, ListGroup, Modal, Form } from 'react-bootstrap';
import profileImage from '../../../img/our-sevices/book set.png';
import '../css/ProfileDashboard.css';
import '../css/Global.css';
import Sidebar from '../jsx/Sidebar';

const ProfileDashboard = ({ onLogout }) => {
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [image, setImage] = useState(null);
  const [shippingAddress, setShippingAddress] = useState({ address: '', city: '', country: '' });
  const [billingAddress, setBillingAddress] = useState({ address: '', city: '', country: '' });
  const [orders, setOrders] = useState([]); // Added state for orders
  const [designs, setDesigns] = useState([]); // Added state for designs

  // Fetch profile data from the backend
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch('https://api.printforu.in/api/v1/adminProfile/profile');
        if (!response.ok) throw new Error('Failed to fetch profile');
        const data = await response.json();
        setName(data.name);
        setEmail(data.email);
        setNumber(data.phone);
        setImage(data.profileImage);
        setShippingAddress(data.shippingAddress || { address: '', city: '', country: '' });
        setBillingAddress(data.billingAddress || { address: '', city: '', country: '' });
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };
    fetchProfile();
  }, []);

  // Fetch designs from the backend
  useEffect(() => {
    const fetchDesigns = async () => {
      try {
        const response = await fetch('https://api.printforu.in/api/v1/adminUploadDesigns/');
        if (!response.ok) {
          throw new Error('Failed to fetch designs');
        }
        const data = await response.json();
        setDesigns(data);
      } catch (error) {
        console.error('Error fetching designs:', error);
      }
    };
    fetchDesigns();
  }, []);

  // Fetch recent orders
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch('https://api.printforu.in/api/v1/adminOrder/getOrder');
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    fetchOrders();
  }, []);

  const handleShowEditProfile = () => setShowEditProfile(true);
  const handleCloseEditProfile = () => setShowEditProfile(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setImage(reader.result); // Save image as base64 URL
      reader.readAsDataURL(file);
    }
  };

  const handleSaveProfile = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Email validation
    if (!emailPattern.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    try {
      const response = await fetch('https://api.printforu.in/api/v1/adminProfile/profile', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, email, phone: number, profileImage: image, shippingAddress, billingAddress }),
      });
      if (!response.ok) throw new Error('Failed to update profile');
      const data = await response.json();

      // Update local state to reflect changes
      setName(data.name);
      setEmail(data.email);
      setNumber(data.phone);
      setImage(data.profileImage || image); // Update image if changed

      setShowEditProfile(false);
    } catch (error) {
      console.error('Error saving profile:', error);
      alert('There was an error updating your profile. Please try again.');
    }
  };

  // Recent Activity Section
  const RecentActivity = () => (
    <Row className="mt-5">
      <Col xs={12} lg={8} className="mx-auto">
        <Card className="recent-activity shadow fade-in">
          <Card.Body>
            <h3 className="text-primary">Recent Activities</h3>
            <ListGroup variant="flush">
              {orders.slice(-5).map((order) => (  // Show only the last 5 orders
                <ListGroup.Item key={order._id}>
                  <strong>{new Date(order.date).toLocaleString()}:</strong> {` Order #${order._id} is now "${order.status}".`}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );

  return (
    <>
      <Sidebar />
      <div className="profile-dashboard">
        {/* Navbar */}
        <Navbar className="navv" bg="dark" variant="dark" expand="lg" sticky="top">
          <Container fluid>
            <Navbar.Brand href="#home" className='dashh'>Admin Dashboard</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Button variant="danger" onClick={onLogout} className="ml-2">Logout</Button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {/* Profile Section */}
        <Container className="dashb mt-4">
          <Row className="justify-content-center">
            <Col xs={12} sm={8} md={6} lg={4}>
              <Card className="text-center shadow-sm profile-card fade-in">
                <Card.Body>
                  <div className="profile text-black">
                    <label htmlFor="imageUpload" className="image-upload">
                      <img
                        src={image || 'https://via.placeholder.com/100'} // Placeholder for profile image
                        alt="Profile"
                        className="rounded-circle"
                        style={{ background: 'thistle', width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer' }}
                      />
                    </label>
                    <h2>{name}</h2>
                    <p className="detail1 mt-2">{email}</p>
                    <p className="detail1">{number}</p>
                  </div>
                  <Button variant="warning" onClick={handleShowEditProfile}>
                    Edit Profile
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Quick Access Section */}
          <Row className="mt-5">
            <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
              <Card className="quick-access shadow fade-in">
                <Card.Body>
                  <Card.Title>New Orders</Card.Title>
                  <Card.Text className="text-primary">15 Orders</Card.Text>
                  <Button variant="primary" className="w-100">View Orders</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
              <Card className="quick-access shadow fade-in">
                <Card.Body>
                  <Card.Title>Messages</Card.Title>
                  <Card.Text className="text-success">5 New Messages</Card.Text>
                  <Button variant="success" className="w-100">Read Messages</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Recent Activity Section */}
          <RecentActivity />

                  {/* Creative Page Section */}
        <Row className="mt-5">
          <Col xs={12}>
            <Card className="creative-page shadow fade-in">
              <Card.Body>
                <h3 className="text-primary">Your Creative Designs</h3>
                <div className="designs-grid">
                  {designs.map((design) => {
                    console.log(`Image Path: https://api.printforu.in/${design.filePath}`); // Debug log
                    return (
                      <div key={design.id} className="design-box">
                        <div className="image-container">
                          <img src={`https://api.printforu.in/public/${design.filePath}`} alt={design.title} className="img-fluid" />
                          <div className="overlay">
                            <h5 className="design-title">{design.title}</h5>
                            <p className="design-category">{design.category}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Link to="/uploadDesign"><Button variant="primary" className="mt-3">Add New Design</Button></Link>
                {/* <Button variant="primary" className="mt-3">Add New Design</Button> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </Container>
      </div>

      {/* Edit Profile Modal */}
      <Modal show={showEditProfile} onHide={() => setShowEditProfile(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formEmail" className="mt-2">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formNumber" className="mt-2">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type="tel" value={number} onChange={(e) => setNumber(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formImage" className="mt-2">
              <Form.Label>Profile Picture</Form.Label>
              <Form.Control type="file" onChange={handleImageChange} />
            </Form.Group>

            <hr />
            <h5>Shipping Address</h5>
            <Form.Group controlId="formShippingAddress" className="mt-2">
              <Form.Label>Address</Form.Label>
              <Form.Control type="text" value={shippingAddress.address} onChange={(e) => setShippingAddress({ ...shippingAddress, address: e.target.value })} />
            </Form.Group>
            <Form.Group controlId="formShippingCity" className="mt-2">
              <Form.Label>City</Form.Label>
              <Form.Control type="text" value={shippingAddress.city} onChange={(e) => setShippingAddress({ ...shippingAddress, city: e.target.value })} />
            </Form.Group>
            <Form.Group controlId="formShippingCountry" className="mt-2">
              <Form.Label>Country</Form.Label>
              <Form.Control type="text" value={shippingAddress.country} onChange={(e) => setShippingAddress({ ...shippingAddress, country: e.target.value })} />
            </Form.Group>

            <h5>Billing Address</h5>
            <Form.Group controlId="formBillingAddress" className="mt-2">
              <Form.Label>Address</Form.Label>
              <Form.Control type="text" value={billingAddress.address} onChange={(e) => setBillingAddress({ ...billingAddress, address: e.target.value })} />
            </Form.Group>
            <Form.Group controlId="formBillingCity" className="mt-2">
              <Form.Label>City</Form.Label>
              <Form.Control type="text" value={billingAddress.city} onChange={(e) => setBillingAddress({ ...billingAddress, city: e.target.value })} />
            </Form.Group>
            <Form.Group controlId="formBillingCountry" className="mt-2">
              <Form.Label>Country</Form.Label>
              <Form.Control type="text" value={billingAddress.country} onChange={(e) => setBillingAddress({ ...billingAddress, country: e.target.value })} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditProfile(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleSaveProfile}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfileDashboard;
