import React from 'react';
import { Container, Row, Col, Card, Button, Form, InputGroup } from 'react-bootstrap';
import Barcode from 'react-barcode';
import '../css/Global.css';
import Sidebar from './Sidebar';

const PaymentPage = () => {
  return (
    <>
      <Sidebar />
      <Container fluid className="dashb py-4">
        <h2 className="text-center mb-4 text-white">Payment Gateway</h2>

        <Row className="gy-4">
          {/* Card Payment Option */}
          <Col xs={12} md={6} lg={4}>
            <Card className="shadow-sm p-3 h-100">
              <Card.Body>
                <h4 className="mb-3">Card Payment</h4>
                <Form>
                  <Form.Group controlId="cardName" className="mb-3">
                    <Form.Label>Cardholder Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter name" />
                  </Form.Group>

                  <Form.Group controlId="cardNumber" className="mb-3">
                    <Form.Label>Card Number</Form.Label>
                    <InputGroup>
                      <Form.Control type="text" placeholder="Enter card number" />
                      <InputGroup.Text>
                        <i className="fas fa-credit-card"></i>
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>

                  <Row className="mb-3">
                    <Col xs={6}>
                      <Form.Group controlId="expiryDate">
                        <Form.Label>Expiry Date</Form.Label>
                        <Form.Control type="text" placeholder="MM/YY" />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group controlId="cvv">
                        <Form.Label>CVV</Form.Label>
                        <Form.Control type="password" placeholder="CVV" />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button variant="primary" type="submit" className="w-100">
                    Pay Now
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>

          {/* Barcode Payment Option */}
          <Col xs={12} md={6} lg={4}>
            <Card className="shadow-sm p-3 h-100">
              <Card.Body>
                <h4 className="mb-3">Pay via Barcode</h4>
                <p>Scan the barcode below using your mobile banking app to complete the payment.</p>
                <div className="text-center">
                  <Barcode value="123456789012" width={2} height={100} displayValue={false} />
                </div>
                <Button variant="outline-secondary" className="w-100 mt-3">
                  Download Barcode
                </Button>
              </Card.Body>
            </Card>
          </Col>

          {/* Payment Summary */}
          <Col xs={12} lg={4}>
            <Card className="shadow-sm p-3 h-100">
              <Card.Body>
                <h4 className="mb-3">Order Summary</h4>
                <p>Items Total: <strong>$120.00</strong></p>
                <p>Shipping: <strong>$5.00</strong></p>
                <p>Total: <strong>$125.00</strong></p>
                <Button variant="success" className="w-100">
                  Confirm Payment
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentPage;
