import React, { useState } from 'react';
import axios from 'axios';
import '../css/LoginForm.css';
import '../css/Global.css';

const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cnFPassword, setConfirmPassword] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [errors, setErrors] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const validate = () => {
    let errors = {};
    let valid = true;

    if (!email) {
      valid = false;
      errors.email = "Please enter your email.";
    } else {
      const lastAtPos = email.lastIndexOf("@");
      const lastDotPos = email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        valid = false;
        errors.email = "Email is not valid.";
      }
    }

    if (!password) {
      valid = false;
      errors.password = "Please enter your password.";
    } else if (password.length < 6) {
      valid = false;
      errors.password = "Password must be at least 6 characters long.";
    }

    if (isRegistering && password !== cnFPassword) {
      valid = false;
      errors.cnFPassword = "Passwords do not match.";
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (validate()) {
      setIsSubmitting(true);
      try {
        const endpoint = isRegistering
          ? "https://api.printforu.in/api/v1/adminLogin/register"
          : "https://api.printforu.in/api/v1/adminLogin/login";

        const payload = isRegistering
          ? { email, password, cnFPassword }
          : { email, password };

        await axios.post(endpoint, payload, { withCredentials: true });
        alert(isRegistering ? "Registered successfully!" : "Logged in successfully!");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        onLogin(); // Call onLogin after successful login
      } catch (error) {
        console.error("Error during API call:", error);
        if (error.response) {
          setError(error.response.data.message || "An error occurred. Please try again.");
        } else if (error.request) {
          setError("No response received from server. Please check your network connection.");
        } else {
          setError("An error occurred while setting up the request. Please try again.");
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>{isRegistering ? "Register" : "Login"}</h2>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <div className="password-container">
            <input
              type={passwordVisible ? "text" : "password"}
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
            <button
              type="button"
              className="password-toggle"
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? "Hide" : "Show"}
            </button>
          </div>
          {errors.password && <p className="error">{errors.password}</p>}
        </div>
        {isRegistering && (
          <div className="form-group">
            <label htmlFor="cnFPassword">Confirm Password:</label>
            <input
              type={passwordVisible ? "text" : "password"}
              name="cnFPassword"
              value={cnFPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your password"
            />
            {errors.cnFPassword && <p className="error">{errors.cnFPassword}</p>}
          </div>
        )}
        <button type="submit" className="login-button" disabled={isSubmitting}>
          {isRegistering ? "Register" : "Login"}
        </button>
        <p onClick={() => setIsRegistering(!isRegistering)} className="toggle-link">
          {isRegistering ? "Already have an account? Login" : "Don't have an account? Register"}
        </p>
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
};

export default LoginForm;
