// // LoginFormRoute.jsx
// import React, { useState, useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';

// import FAQ from '../Admin/jsx/FAQ';
// import Notification from '../Admin/jsx/Notification';
// import OrderUpdates from '../Admin/jsx/OrderUpdates';
// import OurOrder from '../Admin/jsx/OurOrder';
// import PaymentPage from '../Admin/jsx/PaymentPage';
// import ProfileDashboard from '../Admin/jsx/ProfileDashboard';
// import SaveDesigns from '../Admin/jsx/SaveDesigns';
// import Sidebar from '../Admin/jsx/Sidebar';
// import Support from '../Admin/jsx/Support';
// import UploadDesigns from '../Admin/jsx/UploadDesigns';
// import LoginForm from '../Admin/jsx/LoginForm';

// const LoginFormRoute = () => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [darkMode, setDarkMode] = useState(false);
//   const [currentPage, setCurrentPage] = useState('login');

//   // Check login status and dark mode on initial load
//   useEffect(() => {
//     const storedLoginStatus = localStorage.getItem('isLoggedIn') === 'true';
//     const storedDarkMode = localStorage.getItem('darkMode') === 'true';

//     setIsLoggedIn(storedLoginStatus);
//     setDarkMode(storedDarkMode);
//     document.body.className = storedDarkMode ? 'dark-mode' : 'light-mode';
//   }, []);

//   const handleLogin = () => {
//     setIsLoggedIn(true);
//     localStorage.setItem('isLoggedIn', 'true'); // Save login status
//     console.log('User logged in');
//     setCurrentPage('profile'); // Redirect to profile on login
//   };

//   const handleLogout = () => {
//     setIsLoggedIn(false);
//     localStorage.removeItem('isLoggedIn'); // Clear login status
//     console.log('User logged out');
//     setCurrentPage('login'); // Redirect to login on logout
//   };

//   const toggleDarkMode = () => {
//     setDarkMode((prev) => {
//       const newDarkMode = !prev;
//       localStorage.setItem('darkMode', newDarkMode);
//       document.body.className = newDarkMode ? 'dark-mode' : 'light-mode';
//       return newDarkMode;
//     });
//   };

//   const renderPage = () => {
//     if (!isLoggedIn) {
//       return <LoginForm onLogin={handleLogin} />; // Ensure onLogin is passed correctly
//     }

//     switch (currentPage) {
//       case 'profileDashboard':
//         return <ProfileDashboard onLogout={handleLogout} />;
//       case 'uploadDesign':
//         return <UploadDesigns />;
//       case 'ourOrder':
//         return <OurOrder />;
//       case 'saveDesigns':
//         return <SaveDesigns />;
//       case 'notifaction':
//         return <Notification />;
//       case 'orderUpdates': // Duplicate case, not necessary
//         return <OrderUpdates />;
//       case 'paymentPage':
//         return <PaymentPage />;
//       case 'faq':
//         return <FAQ />;
//       case 'support':
//         return <Support />;
//       default:
//         return <ProfileDashboard onLogout={handleLogout} />;
//     }
//   };

//   return (
//     <div className={`app ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//       {isLoggedIn && <Sidebar toggleDarkMode={toggleDarkMode} adminName="Admin Name" />}
      
//       <div className="main-content">
//         <div className="content-wrapper">
//           {renderPage()}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LoginFormRoute;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import FAQ from '../Admin/jsx/FAQ';
import Notification from '../Admin/jsx/Notification';
import OrderUpdates from '../Admin/jsx/OrderUpdates';
import OurOrder from '../Admin/jsx/OurOrder';
import PaymentPage from '../Admin/jsx/PaymentPage';
import ProfileDashboard from '../Admin/jsx/ProfileDashboard';
import SaveDesigns from '../Admin/jsx/SaveDesigns';
import Sidebar from '../Admin/jsx/Sidebar';
import Support from '../Admin/jsx/Support';
import UploadDesigns from '../Admin/jsx/UploadDesigns';
import LoginForm from '../Admin/jsx/LoginForm';

const LoginFormRoute = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null); // State to store user data
  const [darkMode, setDarkMode] = useState(false);
  const [currentPage, setCurrentPage] = useState('login');

  useEffect(() => {
    const storedLoginStatus = localStorage.getItem('isLoggedIn') === 'true';
    const storedDarkMode = localStorage.getItem('darkMode') === 'true';
    setIsLoggedIn(storedLoginStatus);
    setDarkMode(storedDarkMode);
    document.body.className = storedDarkMode ? 'dark-mode' : 'light-mode';
  }, []);

  const handleLogin = async () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
    setCurrentPage('profile');

    try {
      // Fetch data specific to the logged-in user
      const response = await axios.get('https://api.printforu.in/api/v1/userData', {
        withCredentials: true,
      });
      setUserData(response.data); // Populate userData with fetched data

    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserData(null); // Clear user data on logout
    localStorage.removeItem('isLoggedIn');
    setCurrentPage('login');
  };

  const toggleDarkMode = () => {
    setDarkMode((prev) => {
      const newDarkMode = !prev;
      localStorage.setItem('darkMode', newDarkMode);
      document.body.className = newDarkMode ? 'dark-mode' : 'light-mode';
      return newDarkMode;
    });
  };

  const renderPage = () => {
    if (!isLoggedIn) {
      return <LoginForm onLogin={handleLogin} />;
    }

    switch (currentPage) {
      case 'profileDashboard':
        return <ProfileDashboard onLogout={handleLogout} userData={userData} />;
      case 'uploadDesign':
        return <UploadDesigns />;
      case 'ourOrder':
        return <OurOrder />;
      case 'saveDesigns':
        return <SaveDesigns />;
      case 'notifaction':
        return <Notification />;
      case 'orderUpdates':
        return <OrderUpdates />;
      case 'paymentPage':
        return <PaymentPage />;
      case 'faq':
        return <FAQ />;
      case 'support':
        return <Support />;
      default:
        return <ProfileDashboard onLogout={handleLogout} userData={userData} />;
    }
  };

  return (
    <div className={`app ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      {isLoggedIn && <Sidebar toggleDarkMode={toggleDarkMode} adminName="Admin Name" />}
      
      <div className="main-content">
        <div className="content-wrapper">
          {renderPage()}
        </div>
      </div>
    </div>
  );
};

export default LoginFormRoute;
