// FAQ.jsx
import React from 'react';
import { Accordion } from 'react-bootstrap';
import '../css/FAQ.css'; 
import '../css/Global.css';
import Sidebar from './Sidebar';

const faqs = [
  { question: "What is your return policy?", answer: "You can return any item within 30 days for a full refund." },
  { question: "How long does shipping take?", answer: "Shipping takes 5-7 business days." },
  { question: "Do you offer customer support?", answer: "Yes, we provide 24/7 support via email and chat." },
];

const FAQ = () => {
  return (
    <>
    <Sidebar/>
    <div className="faq-section container py-5">
      <h2 className="text-center mb-4">Frequently Asked Questions</h2>
      <Accordion defaultActiveKey="0">
        {faqs.map((faq, index) => (
          <Accordion.Item eventKey={String(index)} key={index} className="faq-item">
            <Accordion.Header>{faq.question}</Accordion.Header>
            <Accordion.Body>{faq.answer}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
        </>
  );
};

export default FAQ;
