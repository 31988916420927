// import React, { useState } from 'react';
// import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../css/UploadDesigns.css';
// import '../css/Global.css';

// const UploadDesign = () => {
//   const [title, setTitle] = useState('');
//   const [category, setCategory] = useState('');
//   const [file, setFile] = useState(null);

//   const handleUpload = (e) => {
//     e.preventDefault();
//     // Logic for handling file upload
//     console.log({ title, category, file });
//   };

//   return (
//     <Container className="upload-design-page">
//       <Row className="justify-content-center">
//         <Col md={8} lg={6}>
//           <Card className="upload-card shadow-lg p-5 rounded-lg">
//             <Card.Body>
//               <h2 className="text-center mb-4">Upload New Design</h2>
//               <Form onSubmit={handleUpload} className="upload-design-form">
//                 <Form.Group controlId="formDesignTitle">
//                   <Form.Label>Design Title</Form.Label>
//                   <Form.Control
//                     type="text"
//                     value={title}
//                     onChange={(e) => setTitle(e.target.value)}
//                     placeholder="Enter design title"
//                     className="shadow-sm"
//                   />
//                 </Form.Group>

//                 <Form.Group controlId="formCategory" className="mt-3">
//                   <Form.Label>Category</Form.Label>
//                   <Form.Control
//                     as="select"
//                     value={category}
//                     onChange={(e) => setCategory(e.target.value)}
//                     className="shadow-sm"
//                   >
//                     <option value="">Select Category</option>
//                     <option value="Marriage Cards">Marriage Cards</option>
//                     <option value="Posters">Posters</option>
//                     <option value="Books">Books</option>
//                   </Form.Control>
//                 </Form.Group>

//                 <Form.Group controlId="formFile" className="mt-3">
//                   <Form.Label>Upload Design File</Form.Label>
//                   <Form.Control
//                     type="file"
//                     onChange={(e) => setFile(e.target.files[0])}
//                     className="shadow-sm"
//                   />
//                 </Form.Group>

//                 <div className="text-center mt-4">
//                   <Button variant="primary" type="submit" className="shadow-sm px-4 py-2 upload-btn">
//                     Upload Design
//                   </Button>
//                 </div>
//               </Form>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default UploadDesign;



import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/UploadDesigns.css';
import '../css/Global.css';
import Sidebar from './Sidebar';

const UploadDesign = () => {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [file, setFile] = useState(null);

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!title || !category || !file) {
      alert('Please fill in all fields and select a file.');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('category', category);
    formData.append('file', file);

    try {
      const response = await fetch('https://api.printforu.in/api/v1/adminUploadDesigns/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Design uploaded successfully!');
        // Optionally reset the form fields
        setTitle('');
        setCategory('');
        setFile(null);
      } else {
        alert('Error uploading design. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error uploading design. Please try again.');
    }
  };

  return (
    <>
      <Sidebar />
      <Container className="dashb upload-design-page">
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <Card className="upload-card shadow-lg p-5 rounded-lg">
              <Card.Body>
                <h2 className="text-center mb-4">Upload New Design</h2>
                <Form onSubmit={handleUpload} className="upload-design-form">
                  <Form.Group controlId="formDesignTitle">
                    <Form.Label>Design Title</Form.Label>
                    <Form.Control
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Enter design title"
                      className="shadow-sm"
                    />
                  </Form.Group>

                  <Form.Group controlId="formCategory" className="mt-3">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      className="shadow-sm"
                    >
                      <option value="">Select Category</option>
                      <option value="Marriage Cards">Marriage Cards</option>
                      <option value="Posters">Posters</option>
                      <option value="Books">Books</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formFile" className="mt-3">
                    <Form.Label>Upload Design File</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => setFile(e.target.files[0])}
                      className="shadow-sm"
                    />
                  </Form.Group>

                  <div className="text-center mt-4">
                    <Button variant="primary" type="submit" className="shadow-sm px-4 py-2 upload-btn">
                      Upload Design
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UploadDesign;
