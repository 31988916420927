//SuperAdmin/Support.jsx
// Admin
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Card, Alert } from 'react-bootstrap';
import '../../Admin/css/Global.css';
import Sidebar from './Sidebar';

const AdminSupport = () => {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    message: '',
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [supportRequests, setSupportRequests] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.printforu.in/api/v1/adminSupport/support', formData);
      setResponseMessage(response.data.message);
      setIsError(false);
      setFormData({ fullname: '', email: '', message: '' });
      fetchSupportRequests(); // Fetch the updated list of support requests
    } catch (error) {
      setResponseMessage('Failed to submit support request. Please check your connection.');
      setIsError(true);
    }
  };

  // Fetch all support requests from the backend
  const fetchSupportRequests = async () => {
    try {
      const response = await axios.get('https://api.printforu.in/api/v1/adminSupport/getSupportRequests');
      setSupportRequests(response.data);
    } catch (error) {
      console.error('Failed to fetch support requests:', error);
    }
  };

  // Delete a support request
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.printforu.in/api/v1/adminSupport/deleteSupportRequest/${id}`);
      setResponseMessage('Support request deleted successfully.');
      setIsError(false);
      fetchSupportRequests(); // Refresh list after deletion
    } catch (error) {
      setResponseMessage('Failed to delete support request. Please try again.');
      setIsError(true);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const message = {
        text: newMessage,
        sender: 'Admin',
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        isAdmin: true,
      };

      setMessages((prevMessages) => [...prevMessages, message]);
      setNewMessage('');

      // Send the message to the backend
      try {
        await axios.post('https://api.printforu.in/api/v1/liveChat/send', {
          sender: message.sender,
          text: message.text,
          isAdmin: message.isAdmin, // Send isAdmin flag as false
        });
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    }
  };

  useEffect(() => {
    fetchSupportRequests();
  }, []);

  useEffect(() => {
    // Fetch messages from backend on component mount
    const fetchMessages = async () => {
      try {
        const response = await axios.get('https://api.printforu.in/api/v1/liveChat/messages');
        setMessages(response.data.map(msg => ({
          ...msg,
          timestamp: new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        })));
      } catch (error) {
        console.error('Failed to fetch messages:', error);
      }
    };

    fetchMessages();
  }, []);

  return (
    <>
      <Sidebar />
      <Container fluid className="dashb py-4">
        <h2 className="text-center mb-4 text-white">Admin Support</h2>

        {responseMessage && (
          <Row className="mb-3">
            <Col>
              <Alert variant={isError ? 'danger' : 'success'} className="text-center">
                {responseMessage}
              </Alert>
            </Col>
          </Row>
        )}

        {/* Chatbox */}
        <div className="Superadminchatbox-overlay">
          <div className="chatbox-container" style={{ height: '400px', width: '100%', maxWidth: '600px', margin: 'auto' }}>
            <div className="chatbox-header card-header bg-success text-white text-center">
              Chat Support
              <button className="btn-close float-end" aria-label="Close"></button>
            </div>
            <div className="chatbox-messages card-body overflow-auto">
              {messages.map((message, index) => (
                <div key={index} className={`message ${message.sender === 'You' ? 'sent' : 'received'} mb-2`}>
                  <div className="message-text">{message.text}</div>
                  <div className="message-time text-muted small">{message.timestamp}</div>
                </div>
              ))}
            </div>
            <div className="chatbox-input d-flex p-2">
              <input
                type="text"
                className="form-control me-2"
                placeholder="Type a message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
              />
              <button className="btn btn-primary" onClick={handleSendMessage}>
                Send
              </button>
            </div>
          </div>
        </div>

        {/* Display Submitted Support Requests */}
        <Row className="justify-content-center mt-5">
          <Col lg={8}>
            <h4 className="text-center text-white mb-4">Submitted Support Requests</h4>
            {supportRequests.map((request) => (
              <Card key={request._id} className="mb-3 shadow-sm">
                <Card.Body>
                  <Card.Title>Name: {request.fullname}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Email: {request.email}</Card.Subtitle>
                  <Card.Text>Message: {request.message}</Card.Text>
                  <Card.Footer className="text-muted d-flex justify-content-between align-items-center">
                    <span>Submitted on {new Date(request.createdAt).toLocaleString()}</span>
                    <Button variant="danger" onClick={() => handleDelete(request._id)}>Delete</Button>
                  </Card.Footer>
                </Card.Body>
              </Card>
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminSupport;
