import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Badge, Button, Modal, Form } from 'react-bootstrap';
import '../css/OurOrder.css';
import '../../Admin/css/Global.css';
import Sidebar from './Sidebar';

const OurOrders = () => {
  const [orders, setOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({ date: '', customer: '', status: '', total: '' });

  // Load orders from the backend on component mount
  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axios.get('https://api.printforu.in/api/v1/adminOrder/getOrder');
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  // Open modal to add or update an order
  const handleShowModal = (order = null) => {
    setShowModal(true);
    if (order) {
      setCurrentOrder(order);
      setIsUpdating(true);
    } else {
      setCurrentOrder({ date: '', customer: '', status: '', total: '' });
      setIsUpdating(false);
    }
  };

  // Save or update order
  const handleSaveOrder = async () => {
    try {
      if (isUpdating) {
        await axios.put(`https://api.printforu.in/api/v1/adminOrder/updateOrder/${currentOrder._id}`, currentOrder);
      } else {
        await axios.post('https://api.printforu.in/api/v1/adminOrder/createOrder', currentOrder);
      }
      setShowModal(false);
      fetchOrders();
    } catch (error) {
      console.error("Error saving/updating order:", error);
    }
  };

  const handleDelete = async (orderId) => {
    try {
      await axios.delete(`https://api.printforu.in/api/v1/adminOrder/deleteOrder/${orderId}`);
      fetchOrders();
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  return (
    <>
      <Sidebar />
      <Container fluid className="our-orders-container mt-4 fade-in">
        <h2 className="text-center mb-4 text-white"><b>Our Orders</b></h2>
        <div className="d-flex justify-content-end mb-4">
          <Button variant="success" onClick={() => handleShowModal()}>Add Order</Button>
        </div>

        <Row>
          {orders.map((order) => (
            <Col lg={4} md={6} sm={12} key={order._id} className="mb-4">
              <Card>
                <Card.Body>
                  <div className="order-header">
                    <h5>Order ID: {order._id}</h5>
                    <Badge pill className={`status ${order.status.toLowerCase()}`}>{order.status}</Badge>
                  </div>
                  <Card.Text><strong>Date:</strong> {order.date}</Card.Text>
                  <Card.Text><strong>Customer:</strong> {order.customer}</Card.Text>
                  <Card.Text><strong>Total:</strong> {order.total}</Card.Text>

                  <div className="d-flex justify-content-between mt-3">
                    <Button variant="secondary" onClick={() => handleShowModal(order)}>Update</Button>
                    <Button variant="danger" onClick={() => handleDelete(order._id)}>Delete</Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Modal for Add / Update Order */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{isUpdating ? 'Update Order' : 'Add Order'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formDate">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  value={currentOrder.date}
                  onChange={(e) => setCurrentOrder({ ...currentOrder, date: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="formCustomer" className="mt-3">
                <Form.Label>Customer Name</Form.Label>
                <Form.Control
                  type="text"
                  value={currentOrder.customer}
                  onChange={(e) => setCurrentOrder({ ...currentOrder, customer: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="formTotal" className="mt-3">
                <Form.Label>Total Amount</Form.Label>
                <Form.Control
                  type="text"
                  value={currentOrder.total}
                  onChange={(e) => setCurrentOrder({ ...currentOrder, total: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="formStatus" className="mt-3">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={currentOrder.status}
                  onChange={(e) => setCurrentOrder({ ...currentOrder, status: e.target.value })}
                >
                  <option>Shipped</option>
                  <option>Processing</option>
                  <option>Delivered</option>
                  <option>Cancelled</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
            <Button variant="primary" onClick={handleSaveOrder}>
              {isUpdating ? 'Update Order' : 'Add Order'}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default OurOrders;
