import React, { useState } from 'react';
import { Form, Button, ProgressBar } from 'react-bootstrap';
import '../css/Global.css';
import Sidebar from './Sidebar';

const UploadDesign = () => {
  const [progress, setProgress] = useState(0);

  const handleUpload = () => {
    // Simulate upload progress
    let uploadProgress = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(uploadProgress);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  return (
    <>
      <Sidebar />
      <div className="dashb upload-form">
        <Form>
          <Form.Group controlId="formDesignTitle">
            <Form.Label>Design Title</Form.Label>
            <Form.Control type="text" placeholder="Enter design title" />
          </Form.Group>

          <Form.Group controlId="formDesignImage">
            <Form.Label>Upload Image</Form.Label>
            <Form.Control type="file" />
          </Form.Group>

          <Button variant="primary" onClick={handleUpload}>Upload Design</Button>
          <ProgressBar now={progress} label={`${progress}%`} className="mt-3" />
        </Form>
      </div>
    </>
  );
};

export default UploadDesign;
