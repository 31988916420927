// import React from 'react';
// import { ProgressBar, Card, ListGroup } from 'react-bootstrap';
// import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../css/OrderUpdates.css';
// import '../css/Global.css';

// const OrderUpdates = () => {
//   const orders = [
//     { id: 1, title: 'Order #12345', status: 'In Progress', progress: 60, date: 'Oct 1, 2024' },
//     { id: 2, title: 'Order #12346', status: 'Completed', progress: 100, date: 'Sept 30, 2024' },
//     { id: 3, title: 'Order #12347', status: 'Pending', progress: 30, date: 'Sept 29, 2024' },
//   ];

//   return (
//     <div className="order-updates__container container">
//       <h2 className="text11 text-center mb-5 text-white">Order Updates</h2>

//       <div className="order-updates__card-wrapper">
//         {orders.map((order) => (
//           <Card key={order.id} className="order-update__card mb-4 shadow-lg">
//             <Card.Body>
//               <Card.Title>{order.title}</Card.Title>
//               <Card.Text>Status: {order.status}</Card.Text>
//               <ProgressBar
//                 animated
//                 now={order.progress}
//                 label={`${order.progress}%`}
//                 variant={order.progress === 100 ? 'success' : 'info'}
//               />
//               <Card.Footer className="text-muted">Updated on {order.date}</Card.Footer>
//             </Card.Body>
//           </Card>
//         ))}
//       </div>

//       <div className="order-updates__recent-activity mt-5">
//         <h4>Recent Activity</h4>
//         <ListGroup variant="flush">
//           <ListGroup.Item>
//             <strong>Oct 1, 2024:</strong> Order #12345 has been updated to "In Progress".
//           </ListGroup.Item>
//           <ListGroup.Item>
//             <strong>Sept 30, 2024:</strong> Order #12346 has been marked as "Completed".
//           </ListGroup.Item>
//           <ListGroup.Item>
//             <strong>Sept 29, 2024:</strong> Order #12347 is now "Pending".
//           </ListGroup.Item>
//         </ListGroup>
//       </div>
//     </div>
//   );
// };

// export default OrderUpdates;



import React, { useEffect, useState } from 'react';
import { ProgressBar, Card, ListGroup } from 'react-bootstrap';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/OrderUpdates.css';
import '../css/Global.css';
import Sidebar from './Sidebar';

const OrderUpdates = () => {
  const [orders, setOrders] = useState([]); // State to hold fetched orders
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [error, setError] = useState(null); // State to manage error messages
  const [groupedOrders, setGroupedOrders] = useState({}); // State to hold orders grouped by status

  useEffect(() => {
    // Function to fetch orders from the backend
    const fetchOrders = async () => {
      try {
        const response = await fetch('https://api.printforu.in/api/v1/adminOrder/getOrder'); // Ensure this URL matches your backend
        if (!response.ok) {
          throw new Error('Failed to fetch orders');
        }
        const data = await response.json();
        setOrders(data);
        groupOrdersByStatus(data); // Group orders after fetching
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false); // Stop loading regardless of success or failure
      }
    };

    fetchOrders();
  }, []); // Empty dependency array ensures this runs only once after the first render

  const groupOrdersByStatus = (orders) => {
    const grouped = orders.reduce((acc, order) => {
      // Initialize array for the status if it doesn't exist
      if (!acc[order.status]) {
        acc[order.status] = [];
      }
      acc[order.status].push(order); // Add the order to the corresponding status group
      return acc;
    }, {});
    setGroupedOrders(grouped);
  };

  // Function to calculate percentage based on the order status
  const calculateProgress = (status) => {
    const ordersInStatus = groupedOrders[status];
    if (!ordersInStatus) return 0; // If no orders in this status

    const totalOrders = orders.length;
    const completedOrders = ordersInStatus.length;

    // Calculate the progress percentage
    return Math.round((completedOrders / totalOrders) * 100);
  };

  // Function to format the date in dd-mm-yyyy format
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad with zero if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month and pad with zero if needed
    const year = date.getFullYear(); // Get the full year
    return `${day}-${month}-${year}`; // Return formatted date
  };

  return (
    <>
      <Sidebar />
      <div className="dashb order-updates__container container">
        <h2 className="text11 text-center mb-5 text-white">Order Updates</h2>

        {loading && <p className="text-white">Loading orders...</p>} {/* Loading state */}
        {error && <p className="text-danger">{error}</p>} {/* Error state */}

        <div className="order-updates__card-wrapper">
          {Object.keys(groupedOrders).map((status) => {
            const progress = calculateProgress(status);
            return (
              <Card key={status} className="order-update__card mb-4 shadow-lg">
                <Card.Body>
                  <Card.Title>{`Status: ${status}`}</Card.Title>
                  <Card.Text>{`Total Orders: ${groupedOrders[status].length}`}</Card.Text>
                  <ProgressBar
                    animated
                    now={progress}
                    label={`${progress}%`}
                    variant={status === 'Completed' ? 'success' : 'info'}
                  />
                  <Card.Footer className="text-muted">Updated on {formatDate(new Date())}</Card.Footer>
                </Card.Body>
              </Card>
            );
          })}
        </div>

        <div className="order-updates__recent-activity mt-5">
          <h4>Recent Activity</h4>
          <ListGroup variant="flush">
            {orders.map((order) => (
              <ListGroup.Item key={order._id}>
                <strong>{order.date}:</strong> {`Order #${order._id} is now "${order.status}".`}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      </div>
    </>
  );
};

export default OrderUpdates;
