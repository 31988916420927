// import React from 'react';
// import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
// import '../css/OurOrder.css';
// import '../css/Global.css';
// import Sidebar from '../jsx/Sidebar';


// const orders = [
//   {
//     id: 1,
//     date: '2024-10-01',
//     customer: 'John Doe',
//     status: 'Shipped',
//     total: '$120.00',
//   },
//   {
//     id: 2,
//     date: '2024-09-30',
//     customer: 'Jane Smith',
//     status: 'Processing',
//     total: '$75.50',
//   },
//   {
//     id: 3,
//     date: '2024-09-29',
//     customer: 'Alice Johnson',
//     status: 'Delivered',
//     total: '$200.00',
//   },
//   {
//     id: 4,
//     date: '2024-09-28',
//     customer: 'Bob Brown',
//     status: 'Cancelled',
//     total: '$50.00',
//   },
// ];

// const OurOrders = () => {
//   return (
//   <>
//     <Sidebar />
//     <Container fluid className="dashb our-orders-container mt-4 fade-in">
//       <h2 className="text-center mb-4 text-white"><b> Our Orders</b></h2>
//       <Row className="">
//         {orders.map((order, index) => (
//           <Col lg={4} md={6} sm={12} key={order.id} className="mb-4">
//             <Card className={`order-${index}`}>
//               <Card.Body>
//                 <div className="order-header">
//                   <h5>Order ID: {order.id}</h5>
//                   <Badge
//                     pill
//                     className={`status ${order.status.toLowerCase()}`}
//                   >
//                     {order.status}
//                   </Badge>
//                 </div>
//                 <Card.Text>
//                   <strong>Date:</strong> {order.date}
//                 </Card.Text>
//                 <Card.Text>
//                   <strong>Customer:</strong> {order.customer}
//                 </Card.Text>
//                 <Card.Text>
//                   <strong>Total:</strong> {order.total}
//                 </Card.Text>
//               </Card.Body>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     </Container>
//   </>
//   );
// };

// export default OurOrders;






import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import '../css/OurOrder.css';
import '../css/Global.css';
import axios from 'axios';
import Sidebar from './Sidebar';

const OurOrders = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://api.printforu.in/api/v1/adminOrder/getOrder');
        console.log(response.data); // Check the response data
        setOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error.response ? error.response.data : error.message);
      }
    };

    fetchOrders();
  }, []);

  return (
   <>
    <Sidebar/>
    <Container fluid className="dashb our-orders-container mt-4 fade-in">
      <h2 className="text-center mb-4 text-white"><b> Our Orders</b></h2>
      <Row>
        {orders.map((order, index) => (
          <Col lg={4} md={6} sm={12} key={order._id} className="mb-4"> {/* Use _id if that's your order ID from DB */}
            <Card className={`order-${index}`}>
              <Card.Body>
                <div className="order-header">
                  <h5>Order ID: {order._id}</h5> {/* Display the actual order ID */}
                  <Badge pill className={`status ${order.status.toLowerCase()}`}>
                    {order.status}
                  </Badge>
                </div>
                <Card.Text>
                  <strong>Date:</strong> {order.date}
                </Card.Text>
                <Card.Text>
                  <strong>Customer:</strong> {order.customer}
                </Card.Text>
                <Card.Text>
                  <strong>Total:</strong> {order.total}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
   </>
  );
};

export default OurOrders;
