// Notifications.jsx
import React, { useEffect, useState } from 'react';
import '../css/Notification.css'; // Your custom styles, if any
import '../../Admin/css/Global.css'; // Your global styles, if any
import Sidebar from './Sidebar';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('info'); // default type
  const [editingNotification, setEditingNotification] = useState(null);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    const response = await fetch('https://api.printforu.in/api/v1/adminNotification/getNotifications');
    const data = await response.json();
    setNotifications(data);
  };

  const handleCreate = async () => {
    const time = new Date().toISOString(); // auto-update time
    const response = await fetch('https://api.printforu.in/api/v1/adminNotification/createNotification', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ title, time, description, type }),
    });
    await response.json();
    fetchNotifications(); // Refresh notifications
    resetForm();
  };

  const handleUpdate = async (notification) => {
    const time = new Date().toISOString(); // auto-update time
    const response = await fetch(`https://api.printforu.in/api/v1/adminNotification/updateNotification/${notification._id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ title, time, description, type }),
    });
    await response.json();
    fetchNotifications(); // Refresh notifications
    resetForm();
  };

  const handleDelete = async (id) => {
    await fetch(`https://api.printforu.in/api/v1/adminNotification/deleteNotification/${id}`, {
      method: 'DELETE',
    });
    fetchNotifications(); // Refresh notifications
  };

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setType('info');
    setEditingNotification(null);
  };

  return (
    <>
      <Sidebar />
      <div className="container mt-4">
        <h2 className="text-center">Notifications</h2>
        <form className="mb-4" onSubmit={(e) => { e.preventDefault(); editingNotification ? handleUpdate(editingNotification) : handleCreate(); }}>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <textarea
              className="form-control"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <select
              className="form-select"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="info">Info</option>
              <option value="warning">Warning</option>
              <option value="error">Error</option>
            </select>
          </div>
          <button type="submit" className="btn btn-primary">{editingNotification ? 'Update' : 'Create'} Notification</button>
        </form>
        <ul className="list-group">
          {notifications.map((notification) => (
            <li className={`list-group-item d-flex justify-content-between align-items-start border-${notification.type}`} key={notification._id}>
              <div>
                <h5 className="mb-1">{notification.title}</h5>
                <p className="mb-1">{notification.description}</p>
                <small className="text-muted">{new Date(notification.time).toLocaleString()}</small>
              </div>
              <div>
                <button
                  className="btn btn-secondary btn-sm me-2"
                  onClick={() => {
                    setTitle(notification.title);
                    setDescription(notification.description);
                    setType(notification.type);
                    setEditingNotification(notification);
                  }}
                >
                  Edit
                </button>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => handleDelete(notification._id)}
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Notifications;
