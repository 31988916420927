// Services.jsx
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Book from "../../img/our-sevices/book set.png";
import Bouch from "../../img/our-sevices/brochures.png";
import Calender from "../../img/our-sevices/calender.png";
import Cate from "../../img/our-sevices/catalogues.png";
import Docum from "../../img/our-sevices/DOCUMENT + BINDING.png";
import Inve from "../../img/our-sevices/invitation card.png";
import Menu from "../../img/our-sevices/menu card.png";
import Pamp from "../../img/our-sevices/pamplate.png";
import Poster from "../../img/our-sevices/poster.png";
import Stick from "../../img/our-sevices/sticker.png";
import Tag from "../../img/our-sevices/tags.png";
import Visi from "../../img/our-sevices/visiting card.png";

import FormModel from "./FormModel";

import '../css/Services.css';

const Services = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [formType, setFormType] = useState(null);

  const services = [
    { id: 1, title: "Books", description: "Some animated book.", image: Book },
    { id: 2, title: "Brochures", description: "Some animated brochure.", image: Bouch },
    { id: 3, title: "Calender", description: "Some Calender we provide.", image: Calender },
    { id: 4, title: "Pamplates", description: "Some animated pamplates.", image: Cate },
    { id: 5, title: "Documents", description: "Documents Binding.", image: Docum },
    { id: 6, title: "Invitation", description: "Cards we provide.", image: Inve },
    { id: 7, title: "Menu cards", description: "Menu cards available.", image: Menu },
    { id: 8, title: "Acrylic", description: "Some animated text for acrylic printing.", image: Pamp },
    { id: 9, title: "Posters", description: "Some animated text for poster printing.", image: Poster },
    { id: 10, title: "Stickers", description: "We provide premium stickers.", image: Stick },
    { id: 11, title: "Tags", description: "We provide various types of tags.", image: Tag },
    { id: 12, title: "Visiting cards", description: "Visiting cards we provide.", image: Visi },
  ];

  const handleClick = (service) => {
    setSelectedService(service);
    setFormType(null);
  };

  const handleClose = () => {
    setSelectedService(null);
    setFormType(null);
  };

  const openRegisterForm = () => setFormType('register');
  const openEnquiryForm = () => setFormType('enquiry');

  return (
    <>
      <section className="services" id="services">
        <div className="SERVICE-overlay">
          <h1 className="text-center mb-5 mt-5 text-white">
            OUR <span style={{ color: "#ecfd2e" }}>SERVICES</span>
          </h1>
          <div className="seo-services-content container mt-5">
            <div className="row">
              {services.map((service) => (
                <div key={service.id} className="col-md-6 col-lg-4 mb-4">
                  <div className="service-box" onClick={() => handleClick(service)}>
                    <div className="service-background">
                      <img src={service.image} alt={service.title} className="service-image img-fluid" />
                    </div>
                    <div className="hover-text">
                      <h3>{service.title}</h3>
                      <p>{service.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {selectedService && (
              <div className="service-detail-overlay">
                <div className="service-detail-box">
                  <h3>{selectedService.title}</h3>
                  <img src={selectedService.image} alt={selectedService.title} className="service-detail-image img-fluid" />
                  <p>{selectedService.description}</p>
                  <div className="btn-group">
                    <button className="btn btn-secondary" onClick={openRegisterForm}>
                      Order Now         {/*  Register */}
                    </button>
                    <button className="btn btn-primary" onClick={openEnquiryForm}>
                      Enquire
                    </button>
                  </div>
                </div>
              </div>
            )}

            {formType && <FormModel formType={formType} onClose={handleClose} />}
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
