// AppRoutes.jsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Port from '../jsx/Port';
import About from '../jsx/About';
import Services from '../jsx/Services';
import FormModel from '../jsx/FormModel';
import Portfolio from '../jsx/Portfolio';
import Contact from '../jsx/Contact';
import ProductSection from '../jsx/ProductSection';
import Footer from '../jsx/Footer';

// Admin imports
import LoginFormRoute from './LoginFormRoute';
import FAQ from '../Admin/jsx/FAQ';
import Notification from '../Admin/jsx/Notification';
import OrderUpdates from '../Admin/jsx/OrderUpdates';
import OurOrder from '../Admin/jsx/OurOrder';
import PaymentPage from '../Admin/jsx/PaymentPage';
import ProfileDashboard from '../Admin/jsx/ProfileDashboard';
import SaveDesigns from '../Admin/jsx/SaveDesigns';
import Sidebar from '../Admin/jsx/Sidebar';
import Support from '../Admin/jsx/Support';
import UploadDesigns from '../Admin/jsx/UploadDesigns';
import UploadForms from '../Admin/jsx/UploadForms';


// SuperAdmin
import Ourorder from '../SuperAdmin/jsx/OurOrder';
import Savedesigns from '../SuperAdmin/jsx/SaveDesigns';
import NotificationSuperAdmin from '../SuperAdmin/jsx/Notification';
import SidebarSuperAdmin from '../SuperAdmin/jsx/Sidebar';
import SupportSuperAdmin from '../SuperAdmin/jsx/Support';

const AppRoutes = () => {
  return (
    <Routes>
      {/* User-facing routes */}
      <Route path="/" element={<Port />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Services />} />
      <Route path="/formModel" element={<FormModel />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/productSection" element={<ProductSection />} />
      <Route path="/footer" element={<Footer />} />

      {/* Admin-facing routes */}
      <Route path="/profileDashboard" element={<ProfileDashboard />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/notifaction" element={<Notification />} />
      <Route path="/orderUpdates" element={<OrderUpdates />} />
      <Route path="/ourOrder" element={<OurOrder />} />
      <Route path="/paymentPage" element={<PaymentPage />} />
      <Route path="/saveDesigns" element={<SaveDesigns />} />
      <Route path="/sidebar" element={<Sidebar />} />
      <Route path="/support" element={<Support />} />
      <Route path="/uploadForms" element={<UploadForms />} />
      <Route path="/uploadDesign" element={<UploadDesigns />} />


      {/* SuperAdmin */}
      <Route path="/superadmin" element={<SidebarSuperAdmin />} />
      <Route path="/superadminourOrder" element={<Ourorder />} />
      <Route path="/superadminsaveDesigns" element={<Savedesigns />} />
      <Route path="/superadminNotification" element={<NotificationSuperAdmin />} />
      <Route path="/superadminSupport" element={<SupportSuperAdmin />} />


      {/* Login form route */}
      <Route path="/loginForm" element={<LoginFormRoute />} />
    </Routes>
  );
};

export default AppRoutes;
