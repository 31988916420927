// // FormModel.jsx
import React, { useState } from 'react';
import axios from 'axios';
import '../css/FormModel.css';

const FormModal = ({ formType, onClose }) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [query, setQuery] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = formType === 'register' 
      ? { fullName, email, phone } 
      : { fullName, query };

    const url = formType === 'register' 
      ? 'https://api.printforu.in/api/v1/form/register' 
      : 'https://api.printforu.in/api/v1/form/enquiry';

    try {
      const response = await axios.post(url, data);
      alert(response.data.message);
      onClose();
    } catch (error) {
      alert('There was an error submitting the form.');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content animate-slide-in">
        <div className="form-header">
          {formType === 'register' && <h2 className="form-title">Order Now</h2>}    {/*  Register */}
          {formType === 'enquiry' && <h2 className="form-title">Enquiry</h2>}
        </div>

        <form className="form-body" onSubmit={handleSubmit}>
          <div className="form-group">
            <input 
              type="text" 
              placeholder="Full Name" 
              className="formModel-form-control" 
              value={fullName} 
              onChange={(e) => setFullName(e.target.value)} 
              required 
            />
          </div>

          {formType === 'enquiry' ? (
            <div className="form-group">
              <textarea
                placeholder="Your Query"
                className="formModel-form-control"
                rows="4"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                required
              />
            </div>
          ) : (
            <>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email"
                  className="formModel-form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  placeholder="Phone"
                  className="formModel-form-control"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
            </>
          )}
          
          <button type="submit" className="btn submit-btn">Submit</button>
        </form>

        <button className="close-btn" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default FormModal;
